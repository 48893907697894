import { useEffect, useState, useRef } from "react";
import { Layout } from "../../Components/Common/Layout/Layout";
import "./Contact_Client.scss";
import { contactDataClient } from "../../mockData/contact_client";
import axios from "axios";
import { GlobalLoader } from "../../Components/Common/GlobalLoader/GlobalLoader";
import Thankyou from "../../Assets/contact/thankyou.svg";
// import { getAllCountries } from "../../Common/getCountries";
import exclamation from "../../Assets/SVG/exclamation-red.svg";
import arrowDown from "../../Assets/SVG/dropDownArrow.svg";
import { SeoTags } from "../../Components/Common/SeoTags/SeoTags";

const seoData = {
  title: "Contact Zeero: Get in Touch with Our Team ",
  desc: "Get in touch with Zeero for inquiries, support, or collaborations. Reach out to us via email at info@zeero.us.",
  canonical: "contact/client",
};

const initFormData = {
  name: "",
  email: "",
  mobile: "",
  countryCode: "+91",
  country: "",
  companyName: "",
  designation: "",
  role: "",
  location: "",
};

const initErrorFormData = {
  name: {
    error: false,
    message: "Missing",
  },
  email: {
    error: false,
    message: "email Missing",
  },
  mobile: {
    error: false,
    message: "Missing",
  },
  country: {
    error: false,
    message: "country Missing",
  },
  companyName: {
    error: false,
    message: "Missing",
  },
  designation: {
    error: false,
    message: "Missing",
  },
  role: {
    error: false,
    message: "Missing",
  },
  location: {
    error: false,
    message: "Missing",
  },
};

export const Contact_Client = () => {
  useEffect(() => {
    const ele = document?.getElementById("main-d");
    ele?.scrollIntoView();
  }, []);
  return (
    <>
      <SeoTags seoData={seoData} />
      <div id="main-d">
        <Layout>
          <div className="client-wrapper">
            <LeftSide data={contactDataClient?.leftSide} />
            <RightSide data={contactDataClient?.rightSide} />
          </div>
        </Layout>
      </div>
    </>
  );
};

const LeftSide = ({ data }) => {
  const [formData, setFormData] = useState({ ...initFormData });
  const [countries, setCountries] = useState([]);
  const [countryCode, setCountryCode] = useState(["+91", "+1"]);
  const [role, setRole] = useState([
    "Frontend Developer",
    "Backend Developer",
    "Fullstack Developer",
    "Data Science Engineer",
  ]);
  const [viewLoader, setViewLoader] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [formError, setFormError] = useState({ ...initErrorFormData });

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "mobile") {
      let newArr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      if (value.length > 10) return;
      let newVal = value.split("");

      for (let val of newVal) {
        if (!newArr.includes(parseInt(val))) {
          return;
        } else {
          // console.log("found==>", newArr.indexOf(parseInt(val)));
        }
      }
    }

    if (name === "name") {
      const alphabeticOnly = value.replace(/[^a-zA-Z]/g, "");
      setFormData((prevState) => ({
        ...prevState,
        [name]: alphabeticOnly,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    setFormError((prev) => ({
      ...prev,
      [name]: {
        error: false,
        message: "",
      },
    }));
  };

  const handleDropDownChange = (val, name) => {
    const event = {
      target: {
        name: name,
        value: val,
      },
    };
    handleChange(event);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (checkFormData(formData, setFormError)) {
      try {
        setViewLoader(true);
        const apiRequestData = generateFormData(formData);
        const endPoint = "/user/contact/client";
        const data = await axios.post(endPoint, apiRequestData);
        if (data) {
          setSubmitSuccess(true);
        }
      } catch (err) {
        console.log("submit client request error ==>", err);
      } finally {
        setViewLoader(false);
      }
    }
  };

  const getCountryList = async () => {
    try {
      const tempCountries = ["India", "United States", "Canada"];
      setCountries([...tempCountries]);
      //   const res = await getAllCountries();
      // setCountries([...res]);
    } catch (err) {
      setCountries([]);
      console.log("error in country fetching==>", err);
    }
  };

  const getAllNecessaryData = async () => {
    setViewLoader(true);
    await getCountryList();
    setViewLoader(false);
  };

  useEffect(() => {
    getAllNecessaryData();
  }, []);

  return (
    <>
      {viewLoader && <GlobalLoader />}
      {submitSuccess ? (
        <SuccessSubmit />
      ) : (
        <div className="left-main">
          <div className="title">{data?.title}</div>
          <div className="desc">{data?.desc}</div>
          <div className="form-container">
            <form onSubmit={handleSubmit} className="contact-form">
              <div className="each-row">
                <div className="each-item">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData?.name}
                    onChange={handleChange}
                    placeholder="Full Name"
                    className={formError?.name?.error ? "errorInput" : ""}
                  />
                  {formError?.name?.error && (
                    <div className="missing">
                      <div>
                        <img src={exclamation} alt="" />
                      </div>
                      <div className="mandatory">
                        {formError?.name?.message}
                      </div>
                    </div>
                  )}
                </div>
                <div className="each-item">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData?.email}
                    onChange={handleChange}
                    placeholder="Email"
                    className={formError?.email?.error ? "errorInput" : ""}
                  />
                  {formError?.email?.error && (
                    <div className="missing">
                      <div>
                        <img src={exclamation} alt="" />
                      </div>
                      <div className="mandatory">
                        {formError?.email?.message}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="each-row">
                <div className="each-item">
                  <label htmlFor="mobile">Mobile Number</label>
                  <div className="phone-no">
                    <DropDownBox
                      options={countryCode}
                      placeholder=""
                      value={formData?.countryCode}
                      handleChange={(val) =>
                        handleDropDownChange(val, "countryCode")
                      }
                      name="mobile"
                      //   formError={formError}
                    />
                    <input
                      className={[
                        `phone-number ${
                          formError?.mobile?.error && "errorInput"
                        }`,
                      ]}
                      type="text"
                      id="mobile"
                      name="mobile"
                      value={formData?.mobile}
                      onChange={handleChange}
                      placeholder="Mobile"
                    />
                    {formError?.mobile?.error && (
                      <div className="missing">
                        <div>
                          <img src={exclamation} alt="" />
                        </div>
                        <div className="mandatory">
                          {formError?.mobile?.message}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="each-item">
                  <label htmlFor="country">Country</label>
                  <DropDownBox
                    options={countries}
                    placeholder="Country"
                    value={formData?.country}
                    handleChange={(val) => handleDropDownChange(val, "country")}
                    name="country"
                    formError={formError}
                  />
                </div>
              </div>

              <div className="each-row">
                <div className="each-item">
                  <label htmlFor="companyName">Company Name</label>
                  <input
                    type="text"
                    id="companyName"
                    name="companyName"
                    value={formData?.companyName}
                    onChange={handleChange}
                    placeholder="Company Name"
                    className={
                      formError?.companyName?.error ? "errorInput" : ""
                    }
                  />
                  {formError?.companyName?.error && (
                    <div className="missing">
                      <div>
                        <img src={exclamation} alt="" />
                      </div>
                      <div className="mandatory">
                        {formError?.companyName?.message}
                      </div>
                    </div>
                  )}
                </div>
                <div className="each-item">
                  <label htmlFor="designation">Designation</label>
                  <input
                    type="text"
                    id="designation"
                    name="designation"
                    value={formData?.designation}
                    onChange={handleChange}
                    placeholder="Designation"
                    className={
                      formError?.designation?.error ? "errorInput" : ""
                    }
                  />
                  {formError?.designation?.error && (
                    <div className="missing">
                      <div>
                        <img src={exclamation} alt="" />
                      </div>
                      <div className="mandatory">
                        {formError?.designation?.message}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="desc desc2Item">{data?.desc2}</div>

              <div className="each-row">
                <div className="each-item">
                  <label htmlFor="role">Role</label>
                  <DropDownBox
                    options={role}
                    placeholder="Role"
                    value={formData?.role}
                    handleChange={(val) => handleDropDownChange(val, "role")}
                    name="role"
                    formError={formError}
                  />
                </div>
                <div className="each-item">
                  <label htmlFor="location">Location</label>
                  <DropDownBox
                    options={countries}
                    placeholder="Location"
                    value={formData?.location}
                    handleChange={(val) =>
                      handleDropDownChange(val, "location")
                    }
                    name="location"
                    formError={formError}
                  />
                </div>
              </div>

              <button type="submit" style={{backgroundColor:'#0B52D4'}}>Submit</button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

const RightSide = ({ data }) => {
  return (
    <div className="right-main">
      <div className="top-image">
        <img src={data?.image} alt="" />
      </div>
      <div className="location">
        {data?.address?.map((item, index) => (
          <div key={index} className="item">
            {item.Image && (
              <div className="item-image">
                <item.Image />
              </div>
            )}
            <div>
              <div className="title">{item?.title}</div>
              <div className="desc">{item?.desc}</div>
              <div>{item?.phone}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const SuccessSubmit = () => {
  return (
    <div className="thank-you">
      <div>
        <img src={Thankyou} alt="" />
      </div>
      <div className="first">Thankyou for your interest in Zeero!</div>
      <div className="second">
        Your request has been submitted. You can expect a response within 48
        hours. We look forward to connect with you soon!
      </div>
    </div>
  );
};

const DropDownBox = ({
  placeholder,
  options,
  value,
  handleChange,
  disableOptions,
  formError,
  name,
}) => {
  const [viewOptions, setViewOptions] = useState(false);
  const componentRef = useRef(null);

  const dropDownChange = (item) => {
    setViewOptions(false);
    if (item !== value) handleChange(item);
  };

  const handleValue = () => {
    const possibleValuesToIgnore = ["", null, undefined];
    if (possibleValuesToIgnore.includes(value)) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        // Click outside the component
        setViewOptions(false);
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Detach the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Check if the element exists
    if (componentRef.current) {
      if (viewOptions) {
        // If viewOptions is true, add the 'clicked' class
        componentRef.current.classList.add("clickedItem");
      } else {
        // If viewOptions is false, remove the 'clicked' class
        componentRef.current.classList.remove("clickedItem");
      }
    }
  }, [viewOptions]);

  return (
    <div className="dropDown-wrapper" ref={componentRef}>
      <div
        className={[`placeholder ${formError?.[name]?.error && "errorInput"}`]}
        onClick={() => (disableOptions ? "" : setViewOptions(!viewOptions))}
        style={{ opacity: handleValue() ? "1" : "0.7" }}
      >
        <div className={handleValue() ? "value" : "no-value"}>
          {handleValue() ? value : placeholder}
        </div>
        <div>
          {!disableOptions && (
            <img
              src={arrowDown}
              alt=""
              style={{ rotate: viewOptions ? "180deg" : "0deg" }}
            />
          )}
        </div>
      </div>
      {formError?.[name]?.error && (
        <div className="missing">
          <div>
            <img src={exclamation} alt="" />
          </div>
          <div className="mandatory">{formError?.[name]?.message}</div>
        </div>
      )}
      {viewOptions && (
        <div className="options-wrapper">
          {options?.map((item, index) => (
            <div key={index} onClick={() => dropDownChange(item)}>
              {item}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const checkFormData = (formData, setFormError) => {
  const requiredFields = [
    { key: "name", message: "Name cannot be empty" },
    { key: "email", message: "Email cannot be empty" },
    { key: "mobile", message: "Mobile Number cannot be empty" },
    { key: "country", message: "Country cannot be empty" },
    { key: "companyName", message: "Company Name cannot be empty" },
    { key: "designation", message: "Designation cannot be empty" },
    { key: "role", message: "Role cannot be empty" },
    { key: "location", message: "Location cannot be empty" },
  ];

  let allGood = true;

  for (const { key, message } of requiredFields) {
    if (!formData[key]) {
      //   console.log("Please provide==>", key);
      setFormError((prev) => ({
        ...prev,
        [key]: {
          error: true,
          message: message,
        },
      }));
      allGood = false;
    }
  }

  if (formData["mobile"] && formData["mobile"].length < 10) {
    setFormError((prev) => ({
      ...prev,
      ["mobile"]: {
        error: true,
        message: "Mobile Number must be 10 digits",
      },
    }));
    allGood = false;
  }

  //   console.log("before return ==>", allGood);
  if (allGood) return true;
  else return false;
};

const generateFormData = (formData) => {
  const newData = {
    name: formData?.name,
    email: formData?.email,
    mobile: formData?.countryCode + formData?.mobile,
    country: formData?.country,
    companyName: formData?.companyName,
    designation: formData?.designation,
    role: formData?.role,
    location: formData?.location,
  };

  return newData;
};
