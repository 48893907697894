import { useEffect, useState } from "react";
import Popup from "../../Components/Common/popUp/popUp";
import "./RaiseTicket.scss";
import closeIcon from "../../Assets/SVG/Close.svg"
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useAttachments } from "../../hooks/useAttachments";
import { formatFileName } from "../../utils/fileUtils";
import { createTicket } from "../../services/ticketService";
import { Button } from "@mui/material";

export const RaiseTicket = ({ setViewRaiseTicket, setShowNotificationDialog, setNotificationDialogData }) => {
  const [subject, setSubject] = useState("");
  const [issue, setIssue] = useState("");
  const { attachments, handleFileChange, removeAttachment } = useAttachments(setShowNotificationDialog, setNotificationDialogData);

  const generateTicketData = () => {
    const email = localStorage.getItem("email");
    const data = {
      contact: {
        email
      },
      description: issue,
      subject
    };
    const formData = new FormData();
    formData.append("jsonData", JSON.stringify(data));
    if (attachments.length > 0) {
      attachments.forEach((attachment) => {
        formData.append(`attachments`, attachment);
      });
    }
    return formData;
  }

  const handleSubmit = () => {
    const data = generateTicketData();
    createTicket(data, setViewRaiseTicket, setShowNotificationDialog, setNotificationDialogData);
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  });

  return (
    <Popup style={{ "marginTop": "70px" }}>
      <div className="raiseTicketMain">
        <div className="ticket-closeBox" onClick={() => setViewRaiseTicket(false)}>
          <img src={closeIcon} alt="" />
        </div>
        <div className="ticket-title">Raise a ticket</div>
        <div className="ticket-subject">
          <input type="text" value={subject} placeholder="Subject" onChange={(e) => setSubject(e.target.value)} maxLength={255} required></input>
        </div>
        <div className="ticket-issue">
          <textarea
            placeholder="Write your Issue..."
            value={issue}
            onChange={(e) => setIssue(e.target.value)}
            required
          ></textarea>
        </div>
        {attachments.length > 0 ? <div className="attachments">
          {attachments.map((file, index) => (
            <div key={index} className="attachment-container">
              <div className="attachment">
                <img src={URL.createObjectURL(file)} alt="Attachment" />
                <button className="remove-button" onClick={() => removeAttachment(index)}>x</button>
              </div>
              <div className="attachment-name">
                {formatFileName(file.name)}
              </div>
            </div>
          ))}
        </div> : <></>}
        <div className="upload-section">
          <label className="upload-button">
            <AttachFileIcon></AttachFileIcon>
            <input type="file" onChange={handleFileChange} multiple accept="image/*" />
            Attach Screenshot
          </label>
        </div>
        <div className="attachment-info">
          <div>*Max 5 attachments</div><div>
            *Format allowed-Jpeg, jpg, png (5 MB). </div>
        </div>
        <div className="ticket-info">
          You can expect reply in 24 business hours, and track it from Profile section/Notification.
        </div>
        <div className="ticket-post">
          <Button
            disabled={!subject || !issue}
            style={{ cursor: !subject || !issue ? "not-allowed" : "pointer" }}
            onClick={handleSubmit}
            title={!subject || !issue ? "Please enter both subject and issue" : ""}
          >
            Create
          </Button>
        </div>
      </div>
    </Popup>
  );
};
