import { devQues } from "./devQuestions";
import developer_1 from "../Assets/home_developer/developer_1.webp";
import weOffer1 from "../Assets/home_developer/Group.svg";
import weOffer2 from "../Assets/home_developer/Group2.svg";
import weOffer3 from "../Assets/home_developer/Group3.svg";
import weOffer4 from "../Assets/home_developer/Group4.svg";
import weOffer5 from "../Assets/home_developer/Group5.svg";
import weOffer6 from "../Assets/home_developer/Group6.svg";
import vettingProcess from "../Assets/home_developer/vettng-all.webp";
import vettingMobile from "../Assets/home_developer/vetting-mobile.webp";

export const home3Data = {
  breadCrumbs: [
    {
      title: "Home",
      link: "/",
    },
    {
      title: "Developer",
      link: "/developer",
    },
  ],
  first: {
    heading: "",
    data: [
      {
        title: "OPPORTUNITIES YOU NEED TO SUCCEED",
        desc: "Find the right projects, clients and work environment for your skills and experience.",
        buttons: [
          {
            title: "Join Now",
            link: "/login",
            backgroundColor:'#0B52D4'
          },
        ],
        imgLink: developer_1,
      },
    ],
  },
  weoffer: {
    header: "What We Offer ?",
    data: [
      {
        imgLink: weOffer1,
        title: "Community of Top Talent",
        desc: "Join our pool of skilled software developers and get matched with the best projects and clients  and unlock unparalleled career opportunities.",
      },
      {
        imgLink: weOffer2,

        title: "Flexibility and Control",
        desc: "Flexible engagement models to suit your needs. Short or long-term, or for a specific project in your preferred location, you have the maximum control.         ",
      },
      {
        imgLink: weOffer3,

        title: "Faster Project Assignments",
        desc: "With large pool of customers that Zeero associates with, we take 2-3 weeks to align the vetted resource on a project.",
      },
      {
        imgLink: weOffer4,

        title: "Geo Independent",
        desc: "Be part of a globally diverse team, collaborate with professionals from around the world, and gain valuable exposure and learning opportunities.",
      },
      {
        imgLink: weOffer5,

        title: "24x7 Support",
        desc: "Our team of experienced support staff is always on hand to provide you with the support you need to succeed.          ",
      },
      {
        imgLink: weOffer6,

        title: "Guidance and Career Development",
        desc: "Get a candidate success manager in your time-zone, grow with time, promotions and increments. Your career growth is not hampered with Zeero.",
      },
    ],
    // button: {
    //   text: "Apply Now",
    //   link: "/login",
    // },
  },
  vetting: {
    heading: "Our Vetting Process and Create Your Profile",
    imgLink: vettingProcess,
    vettingMobile: vettingMobile,
    // button: {
    //   title: "Join as Developer",
    //   link: "/login",
    // },
  },
  ClientConfession: {
    heading: "what our clients say",
    desc: "Neque, pulvinar vestibulum non aliquam.",
    data: [
      {
        noOfStars: 5,
        title:
          "“Orci vel eget in eu. Integer amet porttitor hendrerit etiam arcu, aliquet duis pretium consequat. Semper sed viverra enim ut nunc.”",
        pic: "https://cdn.dribbble.com/users/2878951/screenshots/14013747/media/603f0b853c409547dfa51cba996f375c.png?compress=1&resize=400x300",
        exp: "Courtney Henry, Google",
      },
      {
        noOfStars: 5,
        title:
          "“Orci vel eget in eu. Integer amet porttitor hendrerit etiam arcu, aliquet duis pretium consequat. Semper sed viverra enim ut nunc.”",
        pic: "https://cdn.dribbble.com/users/2878951/screenshots/14013747/media/603f0b853c409547dfa51cba996f375c.png?compress=1&resize=400x300",
        exp: "Courtney Henry, Google",
      },
      {
        noOfStars: 5,
        title:
          "“Orci vel eget in eu. Integer amet porttitor hendrerit etiam arcu, aliquet duis pretium consequat. Semper sed viverra enim ut nunc.”",
        pic: "https://cdn.dribbble.com/users/2878951/screenshots/14013747/media/603f0b853c409547dfa51cba996f375c.png?compress=1&resize=400x300",
        exp: "Courtney Henry, Google",
      },
      {
        noOfStars: 5,
        title:
          "“Orci vel eget in eu. Integer amet porttitor hendrerit etiam arcu, aliquet duis pretium consequat. Semper sed viverra enim ut nunc.”",
        pic: "https://cdn.dribbble.com/users/2878951/screenshots/14013747/media/603f0b853c409547dfa51cba996f375c.png?compress=1&resize=400x300",
        exp: "Courtney Henry, Google",
      },
    ],
  },

  faqs: {
    title: "Frequently Ask Questions",
    // desc: "Pellentesque cras adipiscing tempus libero vel nullam mauris tellus. Aliquam ultrices tellus consequat amet, lectus aliquam est in neque.",
    mainData: [
      {
        questions: devQues,
      },
    ],
  },
};
