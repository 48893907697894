import { GoogleOAuthProvider } from "@react-oauth/google";
import { TopLogoClient } from "../../Components/topLogo/topLogoClient";
import "./SignupClient.scss"
import { GoogleSso } from "../CandidateLogin/GoogleSso";
// import { LinkedInSso } from "../CandidateLogin/LinkedInSSo";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import axios from "axios";
import { afterClinetSignup } from "../../Common/flowAfterLogin";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Popup from "../../Components/Common/popUp/popUp";
import { PrivacyPolicy } from "../PrivacyPage/privacy_1";
import { obfuscateEmail } from "../../utils/emailHash";
import { SeoTags } from "../../Components/Common/SeoTags/SeoTags";

import { Card, CardContent, Typography, Box ,Button ,CircularProgress,Stack,Grid} from '@mui/material';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CandidateCard from "../../Components/ClientPage/CandidateCard/CandidateCard";
import CandidateCardSkeleton from "../../Components/ClientPage/CandidateCard/CandidateCardSkeleton/CandidateSkeleton";
import { checkUserEmail } from "../../Common/Client";
const seoData = {
  title: "Register with Zeero | Find Top Talent for Your Projects ",
  desc: "Register with Zeero for your software development projects. Drop in your information and requirements, and we'll reach out to you to discuss how Zeero can support your business goals and project needs.",
  canonical: "register",
};

export const SignupClient = () => {
  const navigate = useNavigate();
  const [loading,setIsloading]=useState(false);
  const [email, setEmail] = useState("");
  const [viewEmail, setViewEmail] = useState(true);
  const [viewOtp, setViewOtp] = useState(false);
  const [viewSetPasswords, setViewSetPasswords] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [otp, setOtp] = useState("");
  const [error,setError]=useState("")
  
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [strength, setStrength] = useState({
    hasNumber: false,
    hasUppercase: false,
    hasSpecialChar: false,
    hasLetter: false,
    hasLowercase: false,
    isMinLength: false,
  });
  const [userNotFound, setUserNotFound] = useState(false);
  const [otpRecieved, setOtpRecieved] = useState("");
  const [passwordStregthError, setPasswordStregthError] = useState(false);
  const [samePassword, setSamePassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);

  const checkUserExisting = async (email) => {
    try {
      const endpoint = "/candidate/authentication/user-check-new/signUp";
      const data = {
        email: email.toLowerCase(),
        role: "Client",
      };
      const res = await axios.post(endpoint, data);
      if (res?.data && res?.status === 200) {
        // console.log(res.status); 
        // return res?.data;
        return false;
      } else {
        throw new Error("error");
      }
    } catch (err) {

      if (err?.response?.status === 406) {
       
        if(err?.response?.data?.exception)
        setError("Email Already Exists")
        return true;
      }
      // return false;
      // console.log(err.response.status);
      // console.log(err.response.data);
    }
  };

  const sendOtp = async (email) => {
    try {
      const endpoint = "/candidate/authentication/communication/sendingEmail/client/signup";
      const data = {
        // from: "dev.wissda@gmail.com",
        to: email,
        // name: "Test email",
        // subject: "This is the test email from Developer",
        from: "dev.wissda@gmail.com",
      };
      const res = await axios.post(endpoint, data);
      // console.log(res.data);
      if (res?.data) {
        
        return true;
      }
    } catch (err) {
     
      return false;
    }
  };

  const handleSubmitEmail = async (e) => {
    try{
    e.preventDefault();
    if(email==="") {
      setUserNotFound(true);
      setError("Email is required")
      return;
    }
  
    setIsloading(true)
    const emailobj={
      email:email
    }
    const checkEmail=await checkUserEmail(emailobj);
    if(!(checkEmail?.valid)){
      setUserNotFound(true);
      setError("Invalid User")
      setIsloading(false);
      return
    }
    const found = await checkUserExisting(email);
    if (found) {
      setUserNotFound(true);
      
    } else {
      const otpSent = await sendOtp(email);
      if (otpSent) {
        setViewOtp(true);
        setViewEmail(false);
        setTimeLeft(30);
        setIsTimerActive(true);
      }
    }
    setIsloading(false)
  }
  catch(e){
    setIsloading(false);
  }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();

    // if (otp === otpRecieved) {
    //   setViewOtp(false);
    //   setViewEmail(false);
    //   setViewSetPasswords(true);
    // } else {
    //   setOtpError(true);
    // }
    try {
      const data = {
        email: email,
        otp: otp,
      };
      const endpoint = "/candidate/authentication/otp/validate/client";
      const res = await axios.post(endpoint, data);
      if (res) {
        setViewOtp(false);
        setViewEmail(false);
        setViewSetPasswords(true);
      } else {
        throw new Error("otp verification error");
      }
    } catch (err) {
      setOtpError(true);
 
    }
  };

  const handleEditClick = () => {
    setOtp("");
    setViewOtp(false);
    setViewEmail(true);
  };

  const handleOtpChange = (event) => {
    const value = event.target.value;

    // Check if the value is NaN or exceeds 999999
    if (isNaN(value) || value > 999999 || value < 0) {
      // If invalid, set the quantity state to an empty string
      // setOtp("");
      return;
    } else {
      // If valid, update the quantity state
      if (otpError) {
        setOtpError(false);
      }
      setOtp(value);
    }
  };

  const handleResentOtp = async () => {
    if (timeLeft > 0) return;
   
    try {
      // restart timmer
      // api/function for sending otp
      // Do something on click, e.g., resend the OTP
      // Reset timer
      const res = await sendOtp(email);
      if (res) {
        setTimeLeft(30);
        setIsTimerActive(true);
      }
    } catch (err) {
    
    }
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPasswordStregthError(false);
    setSamePassword(false);

    // Check for at least one number
    const hasNumber = /\d/.test(newPassword);

    // Check for at least one uppercase letter
    const hasUppercase = /[A-Z]/.test(newPassword);

    // Check for at least one special character
    const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(newPassword);

    // Check for at least one letter (either uppercase or lowercase)
    const hasLetter = /[A-Za-z]/.test(newPassword);

    // Check for at least one lowercase letter
    const hasLowercase = /[a-z]/.test(newPassword);

    // Check for minimum length of 8 characters
    const isMinLength = newPassword.length >= 8;

    // Update the state with the new password and strength information
    setPassword(newPassword);
    setStrength({
      hasNumber,
      hasUppercase,
      hasSpecialChar,
      hasLetter,
      hasLowercase,
      isMinLength,
    });
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const getIndicatorStyle = (criteriaMet) => ({
    color: criteriaMet ? "#34A853" : passwordStregthError ? "red" : "#000",
  });

  const handleConfirmPassword = async (e) => {
    e.preventDefault();
    for (let item in strength) {
      if (!strength[item]) {
        setPasswordStregthError(true);
       
        return;
      }
    }

    if (password !== confirmPassword) {
    
      setSamePassword(true);
      return;
    }

    try {
      const endpoint = "/candidate/authentication/user/signup/client";
      const data = {
        email: email,
        password: confirmPassword,
      };
      const res = await axios.post(endpoint, data);
      // console.log(res?.data);
      if (res?.data) {
        // sessionStorage.setItem("email", email);
        localStorage.setItem("email", email);

        afterClinetSignup();
      }
    } catch (err) {
      console.error("confirmPassword error", err);
    }

    // navigate("/login");
  };

  useEffect(() => {
    // Exit early when we reach 0
    if (!timeLeft) {
      setIsTimerActive(false);
      return;
    }

    // Save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // Clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // Add timeLeft as a dependency to reset the interval
    // whenever the timeLeft changes
  }, [timeLeft, isTimerActive]);

  return (
    <>
  <Box className="client-signup-wrapper">
      <SeoTags seoData={seoData} />
      <div className="register-main">
        <Grid sx={{marginTop:"0px"}} container>

       <Grid lg={6} item>
        <LeftImage />
        </Grid>
        {/* <div className={`right-side-main childDiv`}> */}
        <Grid  lg={6}item>
        <div className="right-container-wrapper">
          <div className={`right-side-main `}>
            {viewEmail && (
              <div className="form-main">
                <div>
                  {" "}
                  <h2 className="title">Sign Up</h2>
                </div>
                <form onSubmit={handleSubmitEmail}>
                  <label>Offical Email ID</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => {
                      setUserNotFound(false);
                      
                      setEmail(e.target.value);
                    }}
                    required
                    name="email"
                    placeholder="Email"
                    // className="email"
                  />
                  {userNotFound && (
                    <div className="otpError">
                      <ErrorIcon /> {error}
                    </div>
                  )}

<div className="privacy-consent">
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={() => setIsChecked(!isChecked)}
                    />
                    <div style={{ fontSize: '14px' }}>
  I agree to the{" "}
  <span
    onClick={() => setShowPrivacy(true)}
    style={{ textDecoration: 'underline', cursor: 'pointer' }} // Optional styling for better UX
  >
    Terms & Conditions
  </span>
</div>
                  </div>
               
                  <Button

  variant="contained"
  // className="last-btn"
  sx={{
    backgroundColor: '#0B52D4', // Default background color
    marginTop: '27px', // Margin
    marginBottom:'20px',
    height:'44px',
    borderRadius:'8px',
    width:'430px',
    '&:hover': {
      backgroundColor: '#0B52D4', // Same background on hover
    },
  }}

  type="submit"

  onClick={handleSubmitEmail}
  disabled={!isChecked || loading} // Disabled if not checked or loading
>
  {loading ? <CircularProgress size={24} sx={{color:'white'}}/> : 'Continue'}
</Button>
                </form>
               
              
                {/* <div className="sso-flow">
                  <LinkedInSso />
                </div> */} 
                <div className="login">
                  Already have an Account?{" "}
                  <span onClick={() => navigate("/client-login")}> Login</span>
                </div>
               
              </div>
            )}
            {viewOtp && (
              <div className={`right-side-main-2`}>
               
                <div className="right-item">
                  <div className="title">Confirm Your Email</div>

                  <div className={"subtitle subtitle2"}>
                    Please take a moment to verify your email address we sent an email with an otp to <span>{obfuscateEmail(email)}</span>{" "}
                    <span onClick={() => handleEditClick()}>Edit</span>
                  </div>
                  
                  <div className="form-wrapper">
                    <form onSubmit={handleVerifyOtp}>
                      <div>
                        <label className="label">OTP</label>
                        <input
                          style={{
                            border: otpError ? "1px solid #E5261C" : "",
                            // color: "#4318FF",
                          }}
                          type="number"
                          name="otp"
                          required
                          className="content-input"
                          placeholder="Please enter 6 digit otp to verify"
                          value={otp}
                          min="1"
                          max="999999"
                          onChange={(e) => handleOtpChange(e)}
                        />
                        {otpError && (
                          <div className="otpError">
                            <ErrorIcon />
                            Wrong OTP entered
                          </div>
                        )}
                      </div>
                      <div className="resendOtp">
                        Didn’t receive the code?{" "}
                        <span
                          style={{ opacity: timeLeft > 0 ? ".6" : "1" }}
                          onClick={() => handleResentOtp()}
                        >
                          {" "}
                          Resend OTP
                        </span>{" "}
                        {timeLeft > 0 && <span>{timeLeft}</span>}
                      </div>
                      <div style={{ width: "100%" }}>
                        <input
                          type="submit"
                          className="last-button"
                          value="Verify OTP"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}

            {viewSetPasswords && (
              <div className={`right-side-main-2`}>
                <div className="right-item">
                  <SuccessCard />
                  <div className="title">Set Password</div>

                  <div className="form-wrapper">
                    <form onSubmit={handleConfirmPassword}>
                      <div className="password-wrapper">
                        <label className="label">Password</label>
                      

                       <TextField
                          type={showConfirmPassword ? "text" : "password"}
                          placeholder="Password"
                          fullWidth
                          className="content-input"
                          required
                          name="password"
                          autoComplete="false"
                          style={{
                            backgroundColor: "white",
                            padding: "0px",
                            outline: "none",
                          }}
                          sx={{
                            '.MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'transparent', // Initial border color
                              },
                              '&:hover fieldset': {
                                borderColor: 'transparent', // No border on hover
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'transparent', // No border on focus
                              },
                              height: '50.4px !important' , // Set the height here
                            },
                            height: '50.4px !important' , 
                          }}
                          value={password}
                          onChange={(e) => handlePasswordChange(e)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() =>
                                    setShowConfirmPassword(!showConfirmPassword)
                                  }
                                >
                                  {showConfirmPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />

                        <div className="criteria">
                          <span>*Password Policy: </span>
                          <span style={getIndicatorStyle(strength.hasNumber)}>
                            1 number,
                          </span>
                          <span
                            style={getIndicatorStyle(strength.hasUppercase)}
                          >
                            1 uppercase character
                          </span>
                          <span
                            style={getIndicatorStyle(strength.hasSpecialChar)}
                          >
                            1 special character,
                          </span>
                          <span style={getIndicatorStyle(strength.hasLetter)}>
                            1 letter,
                          </span>
                          <span
                            style={getIndicatorStyle(strength.hasLowercase)}
                          >
                            1 lowercase character
                          </span>
                          <span style={getIndicatorStyle(strength.isMinLength)}>
                            Minimum of 8 characters
                          </span>
                        </div>
                      </div>
                      <div>
                        <label className="label">Confirm Password</label>
                        {/* <input
                      type="password"
                      required
                      className="content-input"
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={(e) => {
                        setSamePassword(false);
                        setConfirmPassword(e.target.value);
                      }}
                    /> */}

                        <TextField
                         sx={{
                          '.MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: 'transparent', // Initial border color
                            },
                            '&:hover fieldset': {
                              borderColor: 'transparent', // No border on hover
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: 'transparent', // No border on focus
                            },
                            height: '50.4px !important', // Set the height here
                          },
                          height: '50.4px !important' , 
                        }}
                          type={showConfirmPassword ? "text" : "password"}
                          placeholder="Confirm Password"
                          fullWidth
                          className="content-input"
                          required
                          name="password"
                          autoComplete="false"
                          style={{
                            backgroundColor: "white",
                            padding: "0px",
                            outline: "none",
                          }}
                          value={confirmPassword}
                          onChange={(e) => {
                            setSamePassword(false);
                            setConfirmPassword(e.target.value);
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() =>
                                    setShowConfirmPassword(!showConfirmPassword)
                                  }
                                >
                                  {showConfirmPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        {samePassword && (
                          <div className="otpError">
                            <ErrorIcon /> Password and Confirm Password should
                            be same
                          </div>
                        )}
                        {passwordStregthError && (
                          <div className="otpError">
                            <ErrorIcon /> Password do not meet criteria
                          </div>
                        )}
                      </div>
                      <div style={{ width: "100%" }}>
                        <input
                          type="submit"
                          className="last-button"
                          value="Confirm"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
            {/* {viewLogin && (

            )} */}
          </div>
        </div>
        {showPrivacy && (
          <div className="Privacy-main-wrapper">
            <Popup onClick={(e) => setShowPrivacy(false)}>
              <PrivacyPolicy closePopUop={() => setShowPrivacy(false)} />
            </Popup>
          </div>
        )}
        </Grid>

</Grid>
      </div>
      </Box>
    </>
  );
};

const LeftImage = () => {
  return (
    <div className="left-image">
      <div className="item-content">
        <TopLogoClient />
        <div className="logo-candidate">
          <img src="./images/ClientLeftImage.svg" alt="" />
          <div>
        <Typography
  sx={{
    color: "#212121",
    fontFamily: "Poppins",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    textAlign:'center',
    marginTop:'32px'
  }}
>
  Your Gateway to Elite Talent!!
</Typography>
</div>
        </div>
       

      
      </div>
    </div>
  );
};

const SuccessCard = () => {
  return (
    <Box 
    sx={{
      display: 'flex',
      justifyContent: 'center', // Centers horizontally
      alignItems: 'center', // Centers vertically
      height: '46px', // Takes full viewport height to ensure vertical centering
      marginBottom:'35px'
    }}
  >
    <Card 
      variant="outlined" 
      sx={{
        width: '341px',
        height: '46px',
        borderRadius: '12px',
        borderColor: 'rgba(52, 168, 83, 0.5)',
        display: 'flex', // Ensure the Card is treated as a flexbox container
        justifyContent: 'center', // Center horizontally
        alignItems: 'center' // Center vertically
      }}
    >
      <Stack 
        direction="row" 
        spacing={1} // Adds space between the icon and text
        sx={{ alignItems: 'center' }} // Center items vertically within the row
      >
        <CheckCircleOutlineOutlinedIcon sx={{ color: 'rgba(52, 168, 83, 1)' }} />
        <Typography 
          sx={{ 
            color: 'rgba(52, 168, 83, 1)', 
            fontSize: '16px', 
            fontWeight: '500' 
          }}
        >
          Your email is successfully verified
        </Typography>
      </Stack>
    </Card>
  </Box>
  
  
  );
};
