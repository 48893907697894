import { Card, CardContent, CardMedia, Typography, Button, Grid, Box } from '@mui/material';
import CandidateCardList from '../CandidateCardList/CandidateCardList';
import { useState } from 'react';
import CalendlyDialog from '../../VishalCalendarly/VishalCalendarly';
 import mask_matched_profiles from  "./CandidateCardListData"
 import contactImage from "../../../../Assets/client_tour/illustrations_contact_us.svg"

const CandidateCardListWithBlur = ({candidateList=[]}) => {
  const [calendaropen,setCalendarOpen]=useState(false)
  const handleOpen=()=>{
    setCalendarOpen(true);
  }
  const hanldeClose=()=>{
    setCalendarOpen(false)
  }
  return (
    <Box sx={{ position: 'relative' }}>
    <CalendlyDialog calendaropen={calendaropen} hanldeClose={hanldeClose}/>
  
    {/* Blurred CandidateCardList */}
    <Box sx={{ filter: 'blur(4px)', pointerEvents: 'none' }}>
      <CandidateCardList candidatesList={candidateList} />
    </Box>
  
    {/* Overlay message */}
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 10,
        textAlign: 'center',
        // backgroundColor: 'rgba(255, 255, 255, 0.8)',
        padding: '20px',
        borderRadius: '12px',
        maxWidth: '100%', // Ensure it fits within the viewport
        overflow: 'visible', // Prevents cropping
      }}
    >

      <Card sx={{ padding:'10px',width: 470, height: 146, borderRadius: '6px', backgroundColor: '#DFE7F6', display: 'flex' }}>
  <CardMedia
    component="img"
    sx={{ width: "145px", height: '100%', objectFit: 'cover' }} // Make the image fill the left side
    image={contactImage} // Replace this with your image URL
    alt="Image"
  />
  <CardContent sx={{ flex: '1 0 auto', display: 'flex', flexDirection: 'column', }}>
   <Typography
  variant="h6"
  component="div"
  sx={{
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: '0.2px'
  }}
>
  Please contact below to gain full access.
</Typography>

    <Box mt={2}>
      <Button variant="contained" sx={{
        backgroundColor: '#0B52D4',
    '&:hover': {
      backgroundColor: '#0B52D4', // Removes the background color on hover
    },
   
      }}
       onClick={handleOpen}>Contact us</Button>
    </Box>
  </CardContent>
</Card>



    </Box>
  </Box>
  
  );
};

export default CandidateCardListWithBlur;
